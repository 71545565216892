import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { NO_VALUE, ROJO_AUTIUS } from "../../lib/constants";
import CustomCardList from "./CustomCardList";
import DaysCalendar from "./DaysCalendar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import { dayRange } from "../../lib/helpers";

const useStyles = makeStyles(() => ({
  rootGrid: {
    maxWidth: "100%",
    margin: "auto",
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      background: "rgb(18,151,91)",
    },
  },
}));

const CalendarTooltip = withStyles({
  tooltip: {
    backgroundColor: "transparent",
  },
})(Tooltip);

const renderDays = (rowDays) => {
  return (
    <div>
      <DaysCalendar
        days={rowDays.days}
        startDate={rowDays.startDate}
      ></DaysCalendar>
    </div>
  );
};

export const CourseSelector = ({ isFullWidth = false, course, setData, isDisable = false }) => {
  return (
    <div>
      <TextField
        fullWidth={isFullWidth}
        disabled={isDisable}
        variant="outlined"
        size="small"
        id="teacher"
        placeholder="disponibility"
        select
        value={course.selectedCourseType}
        onChange={(e) => {
          setData((previusData) => {
            let selectedCourses = previusData.map((cour) => {
              if (cour.id === course.id) {
                cour.selectedCourseType = e.target.value;
              }
              return cour;
            });
            return selectedCourses;
          });
        }}
      >
        {getPriceOptions(course).map(({ value, text }, index) => (
          <MenuItem key={index} value={value}>
            {text}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

const getPriceOptions = (courseData) => {
  return [
    { value: 1, text: "CURSO" },
    ...(courseData.price5pack
      ? [
          {
            value: 2,
            text: "CURSO + 5 CLASES",
          },
        ]
      : []),
    ...(courseData.price10pack
      ? [
          {
            value: 3,
            text: "CURSO + 10 CLASES",
          },
        ]
      : []),
    ...(courseData.price20pack
      ? [
          {
            value: 4,
            text: "CURSO + 20 CLASES",
          },
        ]
      : []),
    ...(courseData.price30pack
      ? [
          {
            value: 5,
            text: "CURSO + 30 CLASES",
          },
        ]
      : []),
  ];
};

export const getNormalPrice = (course) => {
  if (course.selectedCourseType === 1) {
    return course.price;
  } else if (course.selectedCourseType === 2) {
    return course.price5pack + course.pack5price;
  } else if (course.selectedCourseType === 3) {
    return course.price10pack + course.pack10price;
  }else if (course.selectedCourseType === 4) {
    return course.price20pack + course.pack20price;
  }else if (course.selectedCourseType === 5) {
    return course.price30pack + course.pack30price;
  }
};

export const getFinalPrice = (course) => {
  if (course.selectedCourseType === 1) {
    return course.finalPrice;
  } else if (course.selectedCourseType === 2) {
    return course.finalPricePack5 + course.pack5price;
  } else if (course.selectedCourseType === 3) {
    return course.finalPricePack10 + course.pack10price;
  }else if (course.selectedCourseType === 4) {
    return course.finalPricePack20 + course.pack20price;
  }else if (course.selectedCourseType === 5) {
    return course.finalPricePack30 + course.pack30price;
  }
};

const CustomTable = ({
  tableRef,
  data,
  setData,
  validCode,
  defaultPageSize,
  fetchAll = false,
  handleClickAddOpen = () => {},
}) => {
  const columns = [
    {
      title: "FECHA DE INICIO",
      field: "startDate",
      emptyValue: NO_VALUE,
      width: 100,
      render: (data) => (
        <CalendarTooltip
          title={data.days ? renderDays(data) : ""}
          aria-label="add"
          interactive
        >
          <Grid container direction="row" alignItems="center" justify="center">
            <b>
              {moment(data.startDate)
                .locale("es")
                .format("dddd, D MMMM")
                .toUpperCase()}
            </b>
            <VisibilityIcon style={{ paddingLeft: 10 }}></VisibilityIcon>
            <Typography>
              {data.type ? (
                <span
                  style={{
                    backgroundColor:
                      data.type === "ONLINE" ? "#007cba" : "#E3C412",
                    color: data.type === "ONLINE" ? "white" : "black",
                    padding: "5px 3px",
                    margin: "3px 0",
                  }}
                >
                  <b>{data.type}</b>
                </span>
              ) : null}
            </Typography>
          </Grid>
        </CalendarTooltip>
      ),
      cardFormat: "DATE",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center", borderRadius: "20px 00px 0px 0px" },
    },
    {
      title: "HORARIO",
      field: "startTime",
      emptyValue: NO_VALUE,
      width: 100,
      render: (data) => (
        <>
          <Typography>
            {data.startTime ? dayRange(data.startTime) : null}
          </Typography>
          <Typography>
            {data.startTime.slice(0, data.startTime.length - 3)}H |{" "}
            {data.endTime.slice(0, data.startTime.length - 3)}H
          </Typography>
        </>
      ),
      cardFormat: "STRING",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "PLAZAS DISPONIBLES",
      field: "slots",
      emptyValue: NO_VALUE,
      cardFormat: "STRING",
      width: 75,
      render: (data) => {
        if (data.slots - data.assignedStudents <= 0) {
          return (
            <Typography style={{ fontSize: "20px" }}>
              <b style={{ color: "rgb(173, 32, 28)" }}>¡Curso completo!</b>
            </Typography>
          );
        } else if (data.slots - data.assignedStudents <= 5) {
          return (
            <Typography style={{ fontSize: "20px" }}>
              <b style={{ color: "rgb(173, 32, 28)" }}>
                ¡Sólo quedan {data.slots - data.assignedStudents} plazas!
              </b>
            </Typography>
          );
        } else {
          return (
            <Typography style={{ fontSize: "20px" }}>
              <b>{data.slots - data.assignedStudents}</b>
            </Typography>
          );
        }
      },
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "PRECIO",
      field: "price",
      type: "currency",
      width: 100,
      render: (course) => {
        console.log(course.slots, course.assignedStudents, "afirmacion");
        return(
        <>
          <CourseSelector course={course} setData={setData} />
          {validCode ? (
            <>
              {validCode.type === "double" ? (
                <Typography>
                  <span style={{ color: "red" }}>
                    <b>
                      1 CURSO{" "}
                      {`${getFinalPrice(course).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                      })}€`}{" "}
                      {"+1 GRATIS"}
                    </b>
                  </span>
                </Typography>
              ) : (
                <Typography>
                  <strike style={{ color: "red" }}>
                    <span style={{ color: "black" }}>
                      <b>
                        {`${getNormalPrice(course).toLocaleString("es-ES", {
                          minimumFractionDigits: 2,
                        })}€`}{" "}
                      </b>
                    </span>
                  </strike>
                  <span style={{ color: "red" }}>
                    <b>
                      {`${getFinalPrice(course).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                      })}€`}{" "}
                    </b>
                  </span>
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography>
                <span>
                  <b>
                    {getNormalPrice(course).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}
                    €
                  </b>
                </span>
              </Typography>
            </>
          )}
          <Button
            disabled={course.slots - course.assignedStudents <= 0 ? true : false}
            className={classes.acceptButton}
            size="large"
            variant="outlined"
            style={{ borderRadius: "25px" }}
            onClick={() => {
              handleClickAddOpen(course);
            }}
          >
            COMPRAR
          </Button>
        </>
      )},
      currencySetting: { currencyCode: "EUR" },
      emptyValue: NO_VALUE,
      cardFormat: "CURRENCY",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center", borderRadius: "0px 20px 0px 0px" },
    },
  ];

  const classes = useStyles();

  return (
    <Grid container className={classes.rootGrid}>
      <Hidden xsDown>
        <Grid item xs={12}>
          <MaterialTable
            style={{
              border: "4px solid black",
              borderRadius: "25px",
              display: "grid",
              zIndex: 0,
            }}
            tableRef={tableRef}
            columns={columns}
            data={data}
            localization={{
              body: {
                emptyDataSourceMessage: "ACTUALMENTE NO HAY CURSOS DISPONIBLES",
              },
            }}
            options={{
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: ROJO_AUTIUS,
                color: "#FFF",
                fontWeight: "bold",
                paddingTop: 8,
                paddingBottom: 8,
              },
              paging: false,
              pageSize: defaultPageSize,
              pageSizeOptions: [],
              addRowPosition: "first",
              search: false,
              toolbar: false,
            }}
          />
        </Grid>
      </Hidden>
      <Hidden smUp>
        <CustomCardList
        setData={setData}
          validCode={validCode}
          data={data}
          columns={columns}
          fetchAll={fetchAll}
          handleClickAddOpen={handleClickAddOpen}
        ></CustomCardList>
      </Hidden>
    </Grid>
  );
};

export default CustomTable;
