
import {ReactComponent as FaceebokIcon} from '../../../assets/img/facebook.svg'
import {ReactComponent as InstagramIcon} from '../../../assets/img/instagram.svg'
import {ReactComponent as LinkedinIcon} from '../../../assets/img/linkedin.svg'
import {ReactComponent as TiktokIcon} from '../../../assets/img/tiktok.svg'

export const SOCIAL_MEDIA_ICONS = [{
    icon: FaceebokIcon,
    link: 'https://www.facebook.com/autiusautoescuela'
},
{icon: LinkedinIcon,
    link: 'https://www.linkedin.com/company/autiusautoescuelas/mycompany/'
},
{
    icon: InstagramIcon,
    link: 'https://www.instagram.com/autiusautoescuela/'
},
{icon: TiktokIcon,
    link: 'https://www.tiktok.com/@autoescuelasautius'}]