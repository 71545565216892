import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CustomCardItem from "./CustomCardItem";

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid rgba(226, 232, 240)",
    borderLeft: "8px solid rgb(229, 62, 62)",
    backgroundColor: "rgb(245,249,251)",
    cursor: "pointer",
  },
  card2: {
    border: "1px solid rgba(226, 232, 240)",
    borderLeft: "8px solid #ad201c",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  cardElement: {
    padding: "0.5rem",
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      background: "rgb(18,151,91)",
    },
  },
}));

const CustomCardList = ({
  data = [],
  columns = [],
  validCode = "",
  handleClickAddOpen = () => {},
  fetchAll = false,
  setData
}) => {
  const classes = useStyles();

  if (data.length === 0) {
    return (
      <Grid className={classes.card} justify="center" container>
        <Typography>ACTUALMENTE NO HAY CURSOS DISPONIBLES</Typography>
      </Grid>
    );
  }
  return (
    <Grid container direction="column">
      {data &&
        data.map((elem, index) => {
          return (
            <CustomCardItem
            setData={setData}
              validCode={validCode}
              key={index}
              elem={elem}
              index={index}
              columns={columns}
              fetchAll={fetchAll}
              handleClickAddOpen={handleClickAddOpen}
            />
          );
        })}
    </Grid>
  );
};

export default CustomCardList;
