import { Button, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { DATE_FORMAT, DATE_FORMAT_COMPLETE } from "./constants";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

export const changeFormat = (value, format = "STRING") => {
  if (value === "" || value === null) return "";
  if (format === "STRING") {
    return value;
  }
  if (format === "CURRENCY") {
    return `${value.toLocaleString("es-ES", {
      minimumFractionDigits: 2,
    })}€`;
  }
  if (format === "DATE") {
    return moment(value).format(DATE_FORMAT);
  }
  if (format === "DATE_COMPLETE") {
    return moment(value).format(DATE_FORMAT_COMPLETE);
  }
  if (format === "POSITION") {
    return (
      <a
        href={`https://www.google.com/maps?q=${value}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>LUGAR</Button>
      </a>
    );
  }
  if (format === "TYPE_PAYMENT") {
    return (
      <Typography>
        <span
          style={
            value === "Cargo"
              ? {
                  backgroundColor: "rgb(190, 227, 248)",
                  padding: "2px 9px",
                  borderRadius: "20px",
                }
              : {
                  backgroundColor: "rgb(198, 246, 213)",
                  padding: "2px 9px",
                  borderRadius: "20px",
                }
          }
        >
          {value}
        </span>
      </Typography>
    );
  }
  if (format === "RESULT") {
    return (
      <>
        {value === 0 ? (
          <Grid>
            <CloseIcon
              style={{
                padding: 3,
                fontSize: "20px",
                paddding: 5,
                color: "#9b2c2c",
                backgroundColor: "rgba(254, 215, 215)",
                borderRadius: "50%",
              }}
            ></CloseIcon>
          </Grid>
        ) : (
          <Grid>
            <DoneIcon
              style={{
                padding: 3,
                fontSize: "20px",
                color: "rgb(39, 103, 73)",
                backgroundColor: "rgb(154, 230, 180)",
                borderRadius: "50%",
              }}
            ></DoneIcon>
          </Grid>
        )}
      </>
    );
  }
  return "";
};

export const dayRange = (date) => {
  const num = parseInt(date.slice(0, date.length - 6));
  if (7 <= num && num < 13) {
    return (
      <Typography>
        <b>MAÑANAS</b>
      </Typography>
    );
  } else if (13 <= num && num < 16) {
    return (
      <Typography>
        <b>MEDIODIAS</b>
      </Typography>
    );
  } else if (16 <= num && num < 18) {
    return (
      <Typography>
        <b>TARDES</b>
      </Typography>
    );
  } else if (18 <= num && num < 21) {
    return (
      <Typography>
        <b>NOCHES</b>
      </Typography>
    );
  } else {
    return (
      <Typography>
        <b>HORAS</b>
      </Typography>
    );
  }
};
