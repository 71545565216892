import React, { useState } from "react";
import "./MenuItem.css";
import  chevronDown  from "../../../../assets/img/chevronDown.png";



function MenuItem({ children, data, hasSubMenu = false, preciosLink = null }) {
  const [isHovering, setIsHovering] = useState(false);
  if(!data){
    return null
  }
  return (
    <div
      style={{ margin: "10px 15px",display: "flex",padding: "20px 0px" }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div style={{display: 'flex', alignItems: 'center'}}>

      <a style={{  textDecoration: "none" }} href={data.link}>
        <span className="menu-item-text">{children}</span>
      </a>
      {hasSubMenu &&  <img
                src={chevronDown}
                alt="chevronDown"
                style={{ height: "12px", paddingLeft: "5px" }}
              />}
      </div>
      {hasSubMenu && isHovering ? (
        <div
          style={{
            backgroundColor: "white",
            margin: "20px 0px",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            borderRadius: "5px",
            border: "1px solid #e5e5e5",
          }}
        >
          {data?.subMenu.map((subMenuItem) => (
            <a
            className="subMenu"
              style={{
                padding: "10px 20px 10px 20px",
                fontSize: "18px",
                fontWeight: "600",
              }}
              href={subMenuItem.link}
            >
              {subMenuItem.name}
            </a>
          ))}
        
        </div>
      ) : null}
    </div>
  );
}

export default MenuItem;
