import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./MenuItemMobile.css";

function MenuItemMobile({
  children,
  data,
  hasSubMenu = false,
  preciosLink = null,
}) {

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "5px",
      }}
    >
      <div style={{ display: "flex", padding: "10px 15px" }}>
        <a style={{ textDecoration: "none" }} href={data.link}>
          <span className="menu-item-text">{children}</span>
        </a>
      </div>
      {hasSubMenu ? (
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
            padding: "5px 0px",
          }}
        >
          {data.subMenu.map((submenulink) => (
            <a
              style={{
                padding: "8px 35px",
                fontSize: "14px",
                fontWeight: "700",
                color: "black",
              }}
              href={submenulink.link}
            >
              <span className="subMenuMobile">{submenulink.name}</span>
            </a>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default MenuItemMobile;
