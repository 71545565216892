export const menuLinks = {
  2: {
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-zaragoza-sanmiguel/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-zaragoza-sanmiguel/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-zaragoza-san-miguel/" },
    precio: {
      link: "https://autius.com/precios-autoescuela-zaragoza-autius-san-miguel/",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-zaragoza-autius-san-miguel/",
        },
        {
          name: "Financia tu carnet",
          link: "https://autius.com/financia-tu-carnet-autoescuela-san-miguel-zaragoza-autius/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/2",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-san-miguel-zaragoza-autius/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-san-miguel/" },
    privacidad: { link: "https://autius.com/legal-privacidad-san-miguel/" },
    comprasDevoluciones: {
      link: "https://autius.com/politica-compras-san-miguel/",
    },
  },
  3: {
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-zaragoza-actur/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-zaragoza-actur/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-actur/" },
    precio: {
      link: "https://autius.com/autoescuela-en-zaragoza-actur",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-el-actur-autius/",
        },
        {
          name: "Financia tu carnet",
          link: "https://autius.com/financia-tu-carnet-autoescuela-en-zaragoza-actur/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/3",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-en-zaragoza-actur/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/legal-actur/" },
    privacidad: { link: "https://autius.com/legal-privacidad-actur/" },
    comprasDevoluciones: {
      link: "https://autius.com/politica-compras-actur/",
    },
  },
  4:{
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-fraga/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-fraga/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-fraga/" },
    precio: {
      link: "https://autius.com/autius-fraga",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-fraga-autius/",
        },
        {
          name: "Financia tu carnet",
          link: "https://autius.com/financia-tu-carnet-autoescuela-en-fraga/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/5",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-san-miguel-zaragoza-autius/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-fraga/" },
    privacidad: { link: "https://autius.com/legal-privacidad-fraga/" },
    comprasDevoluciones: {
      link: "https://autius.com/politica-compras-fraga/",
    },
  },
  5: {
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-monzon/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-monzon/",
    },
    moto: {link: "sacate-el-carnet-de-moto-en-autius-autoescuela-monzon/"},
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-monzon/" },
    precio: {
      link: "https://autius.com/autius-monzon",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-monzon-autius/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/5",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-monzon/" },
    privacidad: { link: "https://autius.com/legal-privacidad-monzon/" },
    comprasDevoluciones: {
      link: "https://autius.com/politica-compras-monzon/",
    },
  },
  6: {
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-barbastro/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-barbastro/",
    },
    moto: {
      link: "https://autius.com/sacate-el-carnet-de-moto-en-autius-autoescuela-barbastro/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-barbastro/" },
    precio: {
      link: "https://autius.com/precios-autoescuela-en-barbastro-autius/",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-barbastro-autius/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/6",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-san-miguel-zaragoza-autius/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-barbastro/" },
    privacidad: { link: "https://autius.com/legal-privacidad-barbastro/" },
    comprasDevoluciones: {
      link: "https://autius.com/politica-compras-barbastro/",
    },
  },
  7: {
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-madrid-arguelles/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-arguelles-madrid/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-arguelles/" },
    precio: {
      link: "https://autius.com/precios-autoescuela-en-arguelles-madrid-autius/",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-arguelles-madrid-autius/",
        },
        {
          name: "Financia tu carnet",
          link: "https://autius.com/financia-tu-carnet-autoescuela-en-madrid-arguelles/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/7",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-en-madrid-arguelles/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-arguelles/" },
    privacidad: { link: "https://autius.com/legal-privacidad-arguelles/" },
    comprasDevoluciones: {
      link: "https://autius.com/politica-arguelles/",
    },
  },
  8: {
    precio: "",
    myAutius: "https://gestion.autius.com/",
    instagram: "https://www.instagram.com/autiusautoescuela/",
    facebook: "https://www.facebook.com/autiusautoescuela",
    linkedin: "https://www.linkedin.com/company/autiusautoescuelas",
    franquicia: "https://autius.com/franquicias-autius-autoescuela/",
    blog: "https://autius.com/blog/",
    somos: "https://autius.com/autoescuela-en-zaragoza-san-miguel/",
    teoria:
      "https://autius.com/nuestro-metodo-teoria-autoescuela-zaragoza-sanmiguel/",
    cursos: "",
    practica:
      "https://autius.com/nuestro-metodo-practicas-autoescuela-sanmiguel-zaragoza/",
    opiniones:
      "https://autius.com/resenas-autius-autoescuela-san-miguel-zaragoza/",
    contacto: "https://autius.com/contacto-san-miguel/",
    faq: "https://autius.com/faqs/",
    trabaja: "https://autius.com/trabaja-en-autius/",
    cookies: "https://autius.com/politica-de-cookies/",
    avisoLegal: "",
    privacidad: "",
    comprasDevoluciones: "",
  },
  15: {
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-zaragoza-gomez-laguna/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-gomez-laguna-zaragoza/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-gomez-laguna/" },
    precio: {
      link: "https://autius.com/autoescuela-en-zaragoza-gomez-laguna/",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-gomez-laguna-autius/",
        },
        {
          name: "Financia tu carnet",
          link: "https://autius.com/financia-tu-carnet-autoescuela-en-gomez-laguna-autius-zaragoza/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/15",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-en-gomez-laguna-autius-zaragoza/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-gomez-laguna/" },
    privacidad: { link: "https://autius.com/legal-privacidad-gomez-laguna/" },
    comprasDevoluciones: {
      link: "https://autius.com/politica-compras-gomez-laguna/",
    },
  },
  17: {
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-huesca/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-huesca/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-huesca/" },
    precio: {
      link: "https://autius.com/autius-autoescuela-en-huesca/",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-huesca-autius/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/17",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-huesca/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-huesca/" },
    privacidad: { link: "https://autius.com/politica-de-privacidad-huesca/" },
    comprasDevoluciones: {
      link: "https://autius.com/politica-de-compras-y-devoluciones-huesca/",
    },
  },
  18: {
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-pozuelo-madrid/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-en-pozuelo-madrid/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-pozuelo/" },
    precio: {
      link: "https://autius.com/nuestro-metodo-practicas-autoescuela-en-pozuelo-madrid/#",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-pozuelo-madrid-autius/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/18",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-san-miguel-zaragoza-autius/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-pozuelo/" },
    privacidad: { link: "https://autius.com/politica-de-privacidad-pozuelo/" },
    comprasDevoluciones: {
      link: "https://autius.com/politica-de-compras-y-devoluciones-pozuelo/",
    },
  },
  20: {
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-en-delicias/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-para-las-clases-practicas-en-autoescuela-en-madrid-delicias/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-madrid-delicias/" },
    precio: {
      link: "https://autius.com/nuestro-metodo-para-las-clases-practicas-en-autoescuela-en-madrid-delicias",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-delicias-madrid-autius/",
        },
        {
          name: "Financia tu carnet",
          link: "https://autius.com/financia-tu-carnet-autoescuela-delicias-madrid/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/20",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-delicias-madrid/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-madrid-delicias/" },
    privacidad: {
      link: "https://autius.com/politica-de-privacidad-madrid-delicias/",
    },
    comprasDevoluciones: {
      link: "https://autius.com/politica-de-compras-y-devoluciones-madrid-delicias/",
    },
  },
  21: {
    // NO TIENEN NADA AUN
    teoria: {
      link: "https://autius.com/nuestro-metodo-teoria-autoescuela-en-delicias/",
    },
    practica: {
      link: "https://autius.com/nuestro-metodo-para-las-clases-practicas-en-autoescuela-en-madrid-delicias/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-arguelles/" },
    precio: {
      link: "https://autius.com/nuestro-metodo-para-las-clases-practicas-en-autoescuela-en-madrid-delicias",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-delicias-madrid-autius/",
        },
        {
          name: "Financia tu carnet",
          link: "https://autius.com/financia-tu-carnet-autoescuela-delicias-madrid/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/20",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-autoescuela-delicias-madrid/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-madrid-delicias/" },
    privacidad: {
      link: "https://autius.com/politica-de-privacidad-madrid-delicias/",
    },
    comprasDevoluciones: {
      link: "https://autius.com/politica-de-compras-y-devoluciones-madrid-delicias/",
    },
  },
  22: {
    teoria: {
      link: "https://autius.com/metodo-para-la-teorica-en-autius-albacete/",
    },
    practica: {
      link: "https://autius.com/metodo-autius-de-clases-practicas-en-autoescuela-en-albacete/",
    },
    blog: { link: "https://autius.com/blog/" },
    contacto: { link: "https://autius.com/contacto-autius-albacete/" },
    precio: {
      link: "https://autius.com/autius-autoescuela-en-albacete/",
      subMenu: [
        {
          name: "Packs teórica + Prácticas",
          link: "https://autius.com/precios-autoescuela-en-albacete-autius/",
        },
        {
          name: "Financia tu carnet",
          link: "https://autius.com/financia-tu-carnet-en-albacete-con-autoescuela-autius/",
        },
        {
          name: "Próximos cursos",
          link: "https://reservas.autius.com/22",
        },
      ],
    },
    myAutius: { link: "https://gestion.autius.com/" },
    financia: {
      link: "https://autius.com/financia-tu-carnet-en-albacete-con-autoescuela-autius/",
    },
    instagram: { link: "https://www.instagram.com/autiusautoescuela/" },
    facebook: { link: "https://www.facebook.com/autiusautoescuela" },
    linkedin: { link: "https://www.linkedin.com/company/autiusautoescuelas" },
    franquicia: { link: "https://autius.com/franquicias-autius-autoescuela/" },
    cursos: { link: "" },

    faq: { link: "https://autius.com/faqs/" },
    trabaja: { link: "https://autius.com/trabaja-en-autius/" },
    cookies: { link: "https://autius.com/politica-de-cookies/" },
    avisoLegal: { link: "https://autius.com/aviso-legal-albacete/" },
    privacidad: {
      link: "https://autius.com/politica-de-privacidad-albacete/",
    },
    comprasDevoluciones: {
      link: "https://autius.com/politica-de-compras-y-devoluciones-albacete/",
    },
  },
};
