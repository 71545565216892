import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {
  payments as paymentsAPI,
} from "../../api";
import moment from "moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { getFinalPrice } from "../core/CustomTable";

export const getBuyInfo=(course)=>{
  if(course.selectedCourseType === 1){
    return "CURSO"
  }else if(course.selectedCourseType === 2){
    return "CURSO + 5 CLASES"
  }else if(course.selectedCourseType === 3){
    return "CURSO + 10 CLASES"
  }else if(course.selectedCourseType === 4){
    return "CURSO + 20 CLASES"
  }else if(course.selectedCourseType === 5){
    return "CURSO + 30 CLASES"
  }
}

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(1),
  },
  labelRoot: {
    color: "white",
    fontWeight: "bold",
    "&$labelFocused": {
      color: "white",
    },
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
  },
}));

const initialValues = {
  firstName: "",
  lastName1: "",
  lastName2: "",
  phone: "",
  email: "",
};

const CourseDetails = (props) => {
  const {
    centerID,
    selectedCourse = null,
    validCode = null,
    setSelectedCourse = () => {},
  } = props;
  const classes = useStyles();

  const [values, setValues] = useState(initialValues);
  const [course, setCourse] = useState(null);
  const [redsysParameters, setRedsysParameters] = useState({});
  const [confirmButton, setConfirmButton] = useState(false);

  useEffect(() => {
    setValues(initialValues);
    setCourse(selectedCourse);
  }, [selectedCourse]);

  useEffect(() => {
    if (
      values.firstName === "" ||
      values.lastName1 === "" ||
      values.phone === "" ||
      values.email === ""
    ) {
      setConfirmButton(false);
    } else {
      let params = {
        ...values,
        idCenter: centerID,
        centerCourseID: selectedCourse.id,
        price: parseFloat(getFinalPrice(selectedCourse)).toFixed(2),
        codeID: validCode.id,
        selectedCourseType: selectedCourse.selectedCourseType
      };
      console.log(params, "params");
      paymentsAPI.getPaymentCourseDetails(params).then((result) => {
        setRedsysParameters(result.result);
        setConfirmButton(true);
      });
    }
  }, [centerID, selectedCourse, validCode.id, values]);

  const handleChange = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  if (course == null) {
    return <></>;
  }
  return (
    <div
      style={{
        padding: "40px 40px",
        borderRadius: "20px",
      }}
    >
      <Grid container style={{ marginBottom: 20 }}>
        <Button
          startIcon={<ArrowBackIosIcon />}
          style={{ borderRadius: "25px", padding: "10px 20px" }}
          onClick={() => {
            setSelectedCourse(null);
          }}
        >
          VOLVER A CURSOS
        </Button>
      </Grid>
      <Grid container spacing={3}>
        <Grid item container xs={12} sm={6} alignContent="center">
          <Grid item xs={12}>
            <Typography>
              <b style={{ color: "rgb(173, 32, 28)" }}>VAS A COMPRAR:</b>
            </Typography>
            <Typography>
              {getBuyInfo(selectedCourse)}
            </Typography>
            <Typography style={{ marginTop: 10 }}>
              <b style={{ color: "rgb(173, 32, 28)" }}>DIAS DEL CURSO:</b>
            </Typography>
            {course.days.sort().map((day, index) => {
              return (
                <Typography>
                  {moment(day)
                    .locale("es")
                    .format("dddd, D MMMM")
                    .toUpperCase()}
                </Typography>
              );
            })}
            <Typography style={{ marginTop: 10 }}>
              <b style={{ color: "rgb(173, 32, 28)" }}>PRECIO A PAGAR:</b>{" "}
            </Typography>
            <Typography>
              {getFinalPrice(course).toLocaleString("es-ES", {
                minimumFractionDigits: 2,
              })}
              €
            </Typography>
            {validCode ? (
              <>
                <Typography style={{ marginTop: 10 }}>
                  <b style={{ color: "rgb(173, 32, 28)" }}>CÓDIGO APLICADO:</b>{" "}
                </Typography>
                <Typography>{validCode.code}</Typography>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={6}>
          <Typography id="form-dialog-title">
            <b style={{ color: "rgb(173, 32, 28)" }}>INTRODUZCA SUS DATOS</b>
          </Typography>
          <Grid item xs={12}>
            <Typography>
              NOMBRE<span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder=""
              value={values.firstName}
              onChange={(e) => handleChange("firstName", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              PRIMER APELLIDO
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder=""
              value={values.lastName1}
              onChange={(e) => handleChange("lastName1", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>SEGUNDO APELLIDO</Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder=""
              value={values.lastName2}
              onChange={(e) => handleChange("lastName2", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              CORREO ELECTRONICO
              <span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="email"
              placeholder=""
              value={values.email}
              onChange={(e) => handleChange("email", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              TELEFONO<span style={{ color: "rgb(159,46,37)" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder=""
              value={values.phone}
              onChange={(e) => handleChange("phone", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>

            <form
              name="from"
              //PRUEBAS
              // action="https://sis-t.redsys.es:25443/sis/realizarPago"
              // REAL
              action="https://sis.redsys.es/sis/realizarPago"
              method="POST"
            >
              <input
                type="hidden"
                name="Ds_SignatureVersion"
                value="HMAC_SHA256_V1"
              />
              <input
                type="hidden"
                name="Ds_MerchantParameters"
                value={redsysParameters.Ds_MerchantParameters || null}
              />
              <input
                type="hidden"
                name="Ds_Signature"
                value={redsysParameters.Ds_Signature || null}
              />
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <Button
                  disabled={confirmButton ? false : true}
                  type="submit"
                  fullWidth
                  className={classes.acceptButton}
                  size="large"
                  variant="outlined"
                  style={{ borderRadius: "25px" }}
                  // onClick={handleSubmit}
                >
                  <ShoppingCartIcon className={classes.marginRight} />
                  Ir a comprar REDSYS
                </Button>
              </span>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CourseDetails;
