import {
  Button,
  ClickAwayListener,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { dayRange } from "../../lib/helpers";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DaysCalendar from "./DaysCalendar";
import { CourseSelector, getFinalPrice, getNormalPrice } from "./CustomTable";

const showSlots = (data) => {
  if (data.slots - data.assignedStudents <= 0) {
    return (
      <Grid item xs={12}>
        <Typography align="center" style={{ color: "rgb(173, 32, 28)" }}>
          <b style={{ color: "rgb(173, 32, 28)" }}>¡Curso completo!</b>
        </Typography>
      </Grid>
    );
  } else if (data.slots - data.assignedStudents <= 5) {
    return (
      <Grid item xs={12}>
        <Typography align="center" style={{ color: "rgb(173, 32, 28)" }}>
          <b style={{ color: "rgb(173, 32, 28)" }}>
            ¡Sólo quedan {data.slots - data.assignedStudents} plazas!
          </b>
        </Typography>
      </Grid>
    );
  } else {
    return null;
  }
};

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid rgba(226, 232, 240)",
    borderLeft: "8px solid rgb(229, 62, 62)",
    backgroundColor: "rgb(245,249,251)",
    cursor: "pointer",
  },
  card2: {
    border: "1px solid rgba(226, 232, 240)",
    borderLeft: "8px solid #ad201c",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  cardElement: {
    padding: "0.5rem",
  },
  acceptButton: {
    cursor: "pointer",
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      background: "rgb(18,151,91)",
    },
  },
}));

const CalendarTooltip = withStyles({
  tooltip: {
    backgroundColor: "transparent",
  },
})(Tooltip);

const renderDays = (rowDays) => {
  return (
    <div>
      <DaysCalendar
        days={rowDays.days}
        startDate={rowDays.startDate}
      ></DaysCalendar>
    </div>
  );
};

const CustomCardItem = ({
  elem,
  index,
  validCode,
  columns,
  fetchAll = false,
  handleClickAddOpen = () => {},
  setData
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      className={classes.card}
      style={{ marginBottom: 20, padding: 10 }}
      container
      direction="row"
      key={index}
    >
      <Grid container style={{ marginBottom: 10 }}>
        <Typography>
          {elem.type ? (
            <span
              style={{
                backgroundColor: elem.type === "ONLINE" ? "#007cba" : "#E3C412",
                color: elem.type === "ONLINE" ? "white" : "black",
                padding: "5px 3px",
              }}
            >
              <b>{elem.type}</b>
            </span>
          ) : null}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <CalendarTooltip
            title={elem.days ? renderDays(elem) : ""}
            aria-label="add"
            interactive
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Grid container direction="column" onClick={handleTooltipOpen}>
              <Typography>
                <b>INICIO</b>
              </Typography>
              <Typography>
                {moment(elem.startDate)
                  .locale("es")
                  .format("dddd, D MMMM")
                  .toUpperCase()}
              </Typography>
              <VisibilityIcon style={{ paddingLeft: 10 }}></VisibilityIcon>
            </Grid>
          </CalendarTooltip>
        </ClickAwayListener>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {elem.startTime ? dayRange(elem.startTime) : null}
        </Typography>
        <Typography>
          {elem.startTime.slice(0, elem.startTime.length - 3)}H |{" "}
          {elem.endTime.slice(0, elem.startTime.length - 3)}H
        </Typography>
        <Typography></Typography>
      </Grid>
      <Grid container item xs={12} alignContent="center" justify="center">
        <Grid item xs={12}>
            <CourseSelector isFullWidth={true} course={elem} setData={setData} />
        </Grid>
        {validCode ? (
          <>
            {validCode.type === "double" ? (
              <Typography align="center">
                <span style={{ color: "rgb(173, 32, 28)" }}>
                  <b>
                  1 CURSO{" "}
                      {`${getFinalPrice(elem).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                      })}€`}{" "}
                      {"+1 GRATIS"}
                  </b>
                </span>
              </Typography>
            ) : (
              <Typography align="center">
                <strike style={{ color: "rgb(173, 32, 28)" }}>
                  <span style={{ color: "black" }}>
                    <b>
                      {`${getNormalPrice(elem).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                      })}€`}{" "}
                    </b>
                  </span>
                </strike>
                <span style={{ color: "rgb(173, 32, 28)" }}>
                  <b>
                    {`${getFinalPrice(elem).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}€`}{" "}
                  </b>
                </span>
              </Typography>
            )}
          </>
        ) : (
          <>
            <Typography align="center">
              <span>
                <b>
                  {getNormalPrice(elem).toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                  })}
                  €
                </b>
              </span>
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12} style={{ cursor: "pointer" }}>
        {elem.slots - elem.assignedStudents <= 0 ? null : (
          <Button
            disabled={elem.slots - elem.assignedStudents == 0 ? true : false}
            fullWidth
            className={classes.acceptButton}
            size="large"
            variant="outlined"
            style={{ borderRadius: "25px", cursor: "pointer" }}
            onClick={() => {
              handleClickAddOpen(elem);
            }}
          >
            COMPRAR
          </Button>
        )}
      </Grid>

      {showSlots(elem)}
    </Grid>
  );
};

export default CustomCardItem;
