import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { AutiusSmallIconAnimation } from "../../assets/img/AutiusSmallIconAnimation";

export const LoadingIcon = () => (
  <Grid
    container
    display="flex"
    justify="center"
    alignContent="center"
    style={{ marginTop: "150px" }}
  >
    <Grid item>
      <AutiusSmallIconAnimation
        fill="red"
        size={150}
      ></AutiusSmallIconAnimation>
      <Typography
        style={{
          fontSize: 30,
          color: "rgb(173, 32, 28)",
          textAlign: "center",
        }}
      >
        Cargando...
      </Typography>
    </Grid>
  </Grid>
);
