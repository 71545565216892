import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "./DaysCalendar.css";

const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
const WEEKDAYS_LONG = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
];
const WEEKDAYS_SHORT = ["D", "L", "M", "X", "J", "V", "S"];

export default function DaysCalendar({ days, startDate }) {
  const dates =
    days &&
    days.map((day) => {
      const newDay = new Date(day);
      return new Date(
        newDay.getFullYear(),
        newDay.getMonth(),
        newDay.getDate()
      );
    });

  return (
    <div style={{ border: "2px solid black" }}>
      <DayPicker
        locale="es"
        months={MONTHS}
        weekdaysLong={WEEKDAYS_LONG}
        weekdaysShort={WEEKDAYS_SHORT}
        firstDayOfWeek={1}
        initialMonth={new Date(startDate)}
        selectedDays={
          dates
          //   [
          //   new Date(2021, 0, 20),
          //   new Date(2021, 0, 25),
          // ]
        }
      />
    </div>
  );
}
