import axios from "axios";
import API_NAME from "./base";
class ReservasApi {
  constructor() {
    this.service = axios.create({
      baseURL: `${API_NAME}api/reservas`,
      withCredentials: true,
    });
  }

  getOneCenter = (centerID) => {
    return this.service
      .get(`/centers/${centerID}`)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  };

  getAllCodes = ({
    centerID = null,
    orderBy = "name",
    orderDir = "asc",
    isActive = 1,
    page = 1,
    perPage = 100,
  } = {}) => {
    return this.service
      .get(`/codes`, {
        params: { centerID, orderBy, orderDir, isActive, page, perPage },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  };

  getAllCourses = ({
    centerID = null,
    newCourses = null,
    orderBy = "name",
    orderDir = "asc",
    page = 1,
    perPage = 100,
  } = {}) => {
    return this.service
      .get(`/courses`, {
        params: { centerID, newCourses, orderBy, orderDir, page, perPage },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  };
}

const reservas = new ReservasApi();
export default reservas;
