import {
  Button,
  Fade,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { reservas as reservasApi } from "../../api";
import { FADE_TRANSITION } from "../../lib/constants";
import CustomTable from "../core/CustomTable";
import { LoadingIcon } from "../core/LoadingIcon";
import CourseDetails from "./CourseDetails";
import CourseDetailsDoubleCourse from "./CourseDetailsDoubleCourse";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "1100px",
    alignItems: "center",
  },
  acceptButton: {
    backgroundColor: "rgb(18,151,91)",
    "&:hover": {
      background: "rgb(18,151,91)",
    },
  },
}));

const calculateDiscount = (validCode, price) => {
  if (validCode.type === "normal") {
    return price - validCode.discount;
  } else if (validCode.type === "percentage") {
    const amountToDiscount = (price * validCode.discount) / 100;
    return price - amountToDiscount;
  } else {
    return price;
  }
};

const CoursesList = () => {
  const classes = useStyles();

  const { centerID } = useParams();

  const [data, setData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [code, setCode] = useState("");
  const [codes, setCodes] = useState([]);
  const [validCode, setValidCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    setIsLoading(true);
    let params = {
      centerID: centerID,
      newCourses: new Date(),
    };
    reservasApi.getAllCourses(params).then((result) => {
      const coursesWithPrices = result.results.map((course) => {
        course.finalPrice = course.price;
        course.finalPricePack5 = course.price5pack;
        course.finalPricePack10 = course.price10pack;
        course.finalPricePack20 = course.price20pack;
        course.finalPricePack30 = course.price30pack;
        course.selectedCourseType = 1;
        return course;
      });
      setData(coursesWithPrices);
      setIsLoading(false);
    });
    reservasApi.getAllCodes({ isActive: 1, ...params }).then((result) => {
      setCodes(result.results);
    });
  }, [centerID]);
  useEffect(() => {
    if (validCode) {
      if (validCode.type === "double") {
      } else {
        const newData = data.map((course) => {
          course.finalPrice = calculateDiscount(validCode, course.price);
          course.finalPricePack5 = calculateDiscount(
            validCode,
            course.price5pack
          );
          course.finalPricePack10 = calculateDiscount(
            validCode,
            course.price10pack
          );
          course.finalPricePack20 = calculateDiscount(
            validCode,
            course.price20pack
          );
          course.finalPricePack30 = calculateDiscount(
            validCode,
            course.price30pack
          );
          return course;
        });
        setData(newData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validCode]);

  const handleCriteriaChange = (newCode) => {
    setCode(newCode);
  };

  const handleClickAddOpen = (selection) => {
    setSelectedCourse(selection);
  };

  const handleCheckCode = (e) => {
    e.preventDefault();
    codes.forEach((item) => {
      if (item.code === code) {
        setValidCode(item);
        setCode("");
        return;
      }
    });
  };

  return (
    <Fade in={true} timeout={FADE_TRANSITION}>
      <div
        className={classes.root}
        style={{
          margin: "0 auto",
        }}
      >
        {selectedCourse ? (
          <div id="buyForm" style={{ margin: "40px 40px 5px 40px" }}>
            {validCode.type === "double" ? (
              <CourseDetailsDoubleCourse
                setSelectedCourse={setSelectedCourse}
                centerID={centerID}
                selectedCourse={selectedCourse}
                validCode={validCode}
              />
            ) : (
              <CourseDetails
                setSelectedCourse={setSelectedCourse}
                centerID={centerID}
                selectedCourse={selectedCourse}
                validCode={validCode}
              />
            )}
          </div>
        ) : (
          <>
            {isLoading ? (
              <LoadingIcon />
            ) : (
              <>
                <div style={{ margin: "40px 40px 5px 40px" }}>
                  <form onSubmit={handleCheckCode}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justify="flex-end"
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={12} sm="auto">
                        <Typography>
                          <strong>¿Tienes un código promocional?</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <TextField
                          fullWidth
                          style={{ backgroundColor: "white" }}
                          variant="outlined"
                          size="small"
                          placeholder="YOELIJOAUTIUS"
                          value={code}
                          onChange={(e) => {
                            handleCriteriaChange(e.target.value);
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Button
                          fullWidth
                          type="submit"
                          style={{
                            borderRadius: "25px",
                          }}
                        >
                          ¡APLÍCALO!
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>

                <div style={{ padding: "20px 40px 20px 40px" }}>
                  <CustomTable
                    handleClickAddOpen={handleClickAddOpen}
                    validCode={validCode}
                    data={data}
                    setData={setData}
                    hasActions={true}
                    hasPagination={false}
                    handleBuy={handleClickAddOpen}
                  ></CustomTable>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Fade>
  );
};

export default CoursesList;
