import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import autiusLogo from "../../assets/img/autius_reservas.png";
import aAutius from "../../assets/img/a_autius.png";
import MenuItem from "./components/MenuItem/MenuItem";
import { menuLinks } from "./helpers/layoutLinks";
import MenuItemMobile from "./components/MenuItemMobile/MenuItemMobile";
import { reservas as ReservasApi } from "../../api";
import "./Layout.css";
import { SOCIAL_MEDIA_ICONS } from "./helpers/socialmediaIcons";

const Layout = ({ children }) => {
  const { centerID } = useParams();

  const [centerInfo, setCenterInfo] = useState({
    activeCode: null,
    bannerBig: null,
    bannerSmall: null,
    showbanner: false,
  });
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    ReservasApi.getOneCenter(centerID).then(({ results }) => {
      setCenterInfo(results);
    });
  }, [centerID]);

  return (
    <>
      <header>
        <div
          style={{
            height: "70px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: ` #c6000d`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              width: "1000px",
              maxWidth: "1170px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <button
                style={{
                  backgroundColor: "white",
                  padding: "12px 18px",
                  borderRadius: "50px",
                  border: "none",
                  marginLeft: "20px",
                }}
              >
                <a
                  style={{ fontSize: "18px", color: "rgb(198, 0, 13)" }}
                  href="https://gestion.autius.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  My autius
                </a>
              </button>
            </div>

            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: 'center'
              }}
            >
              <a href="https://autius.com/franquicias-autius-autoescuela/">
                <p
                  style={{
                    fontFamily: "Roboto",
                    color: "white",
                    lineHeight: "1.5em",
                    fontSize: "16px",
                    fontWeight: "400",
                    marginRight: "20px",
                  }}
                >
                  ¿Quieres ser franquiciado?
                </p>
              </a>
              <div className="socialMediaGroup" >
                {SOCIAL_MEDIA_ICONS.map((social) => {
                  const Icon = social.icon;
                  return (
                    <a target='_blank' href={social.link} style={{ color: "white" }} rel="noreferrer">
                      <Icon className='socialHeader' fill={"white"} />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            height: "56px",
            backgroundColor: "#29326e",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "1.5em",
          }}
        >
          <span
            className="bannerBig"
            style={{ color: "white", fontSize: "18px" }}
          >
            {centerInfo?.bannerBig || ""}
          </span>
          <span
            className="bannerSmall"
            style={{ color: "white", fontSize: "12px" }}
          >
            {centerInfo?.bannerSmall || ""}
          </span>
          <span
            style={{
              color: "#000",
              backgroundColor: "white",
              padding: "5px 10px 5px 10px",
              marginLeft: "10px",
              borderRadius: "10px",
            }}
          >
            {centerInfo?.activeCode}
          </span>
        </div>
        <div className="menuItemsContainer">
          <div style={{ padding: "0px 15px" }}>
            <a href="https://autius.com/">
              <img
                src={autiusLogo}
                alt="autius-logo"
                style={{ height: "36px" }}
              />
            </a>
          </div>
          <div className="menuDesktop" style={{ padding: "0px 15px" }}>
            <MenuItem data={menuLinks[centerID].teoria}>Teórica</MenuItem>
            <MenuItem data={menuLinks[centerID]?.practica}>Prácticas</MenuItem>
            <MenuItem data={menuLinks[centerID]?.moto}>Moto</MenuItem>
            <MenuItem data={menuLinks[centerID]?.precio} hasSubMenu>
              Precios y Packs
            </MenuItem>
            <MenuItem data={menuLinks[centerID]?.blog}>Blog</MenuItem>
            <MenuItem data={menuLinks[centerID]?.contacto}>Contacto</MenuItem>
          </div>

          <div
            className="menuMobile"
            style={{ padding: "0px 15px", right: "0" }}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {showMobileMenu && (
          <div className="menuMobileFull">
            <div
              onClick={() => setShowMobileMenu(false)}
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <span style={{ fontSize: "24px" }}>x</span>
            </div>
            <div>
              <MenuItemMobile data={menuLinks[centerID].teoria}>
                Teórica
              </MenuItemMobile>
              <MenuItemMobile data={menuLinks[centerID].practica}>
                Prácticas
              </MenuItemMobile>
              <MenuItemMobile data={menuLinks[centerID].precio} hasSubMenu>
                Precios y Packs
              </MenuItemMobile>
              <MenuItemMobile data={menuLinks[centerID].blog}>
                Blog
              </MenuItemMobile>
              <MenuItemMobile data={menuLinks[centerID].contacto}>
                Contacto
              </MenuItemMobile>
            </div>
          </div>
        )}
      </header>

      {/* <h1 style={{ textAlign: "center" }}>Próximos cursos y packs</h1> */}
      <p style={{ textAlign: "center" }}>
        <span style={{ fontSize: "22px" }}>
          Elige el curso que mejor te encaje para poder asistir a clase los 6
          días.
        </span>
      </p>

      {children}

      <footer
        style={{
          justifyContent: "center",
          backgroundColor: "#363839",
          paddingTop: "20px",
          color: "white",
          alignContent: "center",
        }}
      >
        <Grid container direction="flex" justify="space-around" >
          <Grid item={4} className="footerDesktop">
            <div style={{ padding: "0px 15px" }}>
              <img
                src={aAutius}
                alt="autius-logo"
                style={{ height: "100px" }}
              />
            </div>
          </Grid>
          <Grid item={4} className="footerDesktop">
            <h3>Corporativa</h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  margin: "5px 0px",
                }}
                href={menuLinks[centerID].faq.link}
              >
                Faqs
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                href={menuLinks[centerID].franquicia.link}
              >
                Franquicias autius autoescuela
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                href={menuLinks[centerID].trabaja.link}
              >
                Trabaja en autius
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
              >
                info@autius.com
              </a>
            </div>
          </Grid>
          <Grid item={4} className="footerDesktop">
            <h3>autius autoescuelas</h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  margin: "5px 0px",
                }}
                href={menuLinks[centerID].teoria.link}
              >
                Teórica
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
              >
                Prácticas
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                href={menuLinks[centerID].practica.link}
              >
                Packs teórica + Prácticas
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                href={menuLinks[centerID].teoria.link}
              >
                Financia tu carnet
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                href={menuLinks[centerID].practica.link}
              >
                Próximos cursos
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                herf={menuLinks[centerID].blog.link}
              >
                Blog
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                href={menuLinks[centerID].contacto.link}
              >
                Contacto
              </a>
            </div>
          </Grid>
          <Grid item={4}>
            <h3>{"Legal y Condiciones"}</h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  margin: "5px 0px",
                }}
                href={menuLinks[centerID].avisoLegal.link}
              >
                Aviso Legal
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                href={menuLinks[centerID].privacidad.link}
              >
                Politica de Privacidad
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                href={menuLinks[centerID].cookies.link}
              >
                Politica de Cookies
              </a>
              <a
                style={{
                  color: "white",
                  fontSize: "14px",
                  padding: "5px 0px",
                }}
                href={menuLinks[centerID].comprasDevoluciones.link}
              >
                Politica de Compras y Devoluciones
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: '20px 0'}}>
                {SOCIAL_MEDIA_ICONS.map((social) => {
                  const Icon = social.icon;
                  return (
                    <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', margin:'0 5px'}}>

                    <a target='_blank' href={social.link} style={{ color: "#363839", backgroundColor: 'grey', borderRadius: '50%', padding: '5px' }} rel="noreferrer">
                      <Icon className='socialFooter' fill={"white"} />
                    </a>
                    </div>
                  );
                })}
              </Grid>
        <Grid container style={{ display: "flex", justifyContent: "center", margin: '20px 0' }}>
          <span> autius © Todos los derechos reservados</span>
        </Grid>
      </footer>
    </>
  );
};
export default Layout;
