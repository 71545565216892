import React from "react";
import "./animation.css";

export const AutiusSmallIconAnimation = ({ size = 45 }) => (
  <svg
    className="animation-svg"
    width={size}
    height={size}
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.862 8.877h-.577c-.072-2.67-2.274-4.8-4.944-4.8s-4.835 2.13-4.943 4.8h-.542a.74.74 0 00-.757.758v.072a.74.74 0 00.757.758h.578v3.355c0 .397.324.722.721.722a.724.724 0 00.722-.722v-1.01h7v1.01c0 .397.325.722.722.722a.724.724 0 00.722-.722v-3.355h.577a.74.74 0 00.758-.758v-.072c-.036-.397-.397-.758-.794-.758zm-5.52-3.32c1.876 0 3.391 1.48 3.5 3.356H8.876a3.467 3.467 0 013.464-3.356z"
      fill="rgb(173, 32, 28)"
    />
    <path
      className="small-icon-line"
      d="M17.934 5.557a.642.642 0 01-.36.577c-.036.036-.145.072-.253.072zM11.98 1.3c-5.882 0-10.645 3.789-10.645 8.48 0 4.69 4.763 8.48 10.645 8.48.145 0 .289.036.397.144l4.98 3.753.036-4.654c0-.253.145-.434.36-.542 3.068-1.588 4.909-4.258 4.909-7.145C22.625 5.088 17.825 1.3 11.98 1.3z"
      fill="transparent"
    />
  </svg>
);
