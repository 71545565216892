import MomentUtils from "@date-io/moment";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import "./App.css";
import "./react_dates_overrides.css";
import { theme } from "./themes/themes";
import CoursesList from "./components/Courses";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <main style={{ width: "100%" }}>
          <Switch>
            <Route
              exact={true}
              path="/:centerID"
              render={(props) => (
                <Layout>
                  <div
                    style={{
                      margin: "50px 0px"
                    }}
                  >
                    <CoursesList {...props} />{" "}
                  </div>
                </Layout>
              )}
            />
          </Switch>
        </main>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default App;
