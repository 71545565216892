import axios from "axios";
import API_NAME from "./base";

class PaymentsApi {
  constructor() {
    this.service = axios.create({
      baseURL: `${API_NAME}api/payments`,
      withCredentials: true,
    });
  }

  getPaymentCourseDetails = ({
    idCenter = null,
    firstName = null,
    lastName1 = null,
    lastName2 = null,
    email = null,
    phone = null,
    firstNameSecond = null,
    lastName1Second = null,
    lastName2Second = null,
    emailSecond = null,
    phoneSecond = null,
    centerCourseID = null,
    price = null,
    codeID = null,
    selectedCourseType = 1
  } = {}) => {
    return this.service
      .get("/signatureForCourses", {
        params: {
          idCenter,
          firstName,
          lastName1,
          lastName2,
          email,
          phone,
          firstNameSecond,
          lastName1Second,
          lastName2Second,
          emailSecond,
          phoneSecond,
          centerCourseID,
          price,
          codeID,
          selectedCourseType
        },
      })
      .then((response) => response.data)
      .catch((err) => console.error(err));
  };


  // processPaymentCallback = (data) => {
  //   return this.service
  //     .post("/processCourse", data)
  //     .then((response) => response.data)
  //     .catch((err) => console.error(err));
  // };
}

const payments = new PaymentsApi();
export default payments;
