import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  typography: {
    fontFamily: "Museo, Nunito, Lato, sans serif",
    caption: {
      color: "#FFFFFF",
      opacity: 0.4,
    },
    button: {
      textTransform: "none",
      fontWeight: "bold",
    },
  },
  overrides: {
    // MuiCssBaseline: {
    //   "@global": {
    //     "@font-face": [lato],
    //   },
    // },
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
      },
    },
    MuiPaper: {
      rounded: {
        // backgroundColor: "#002C43",
        border: "1px solid rgba(0,23,44,0.4)",
        boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.24)",
        borderRadius: "3px",
        "&:hover": {
          border: "1px solid #007fbd",
          boxShadow: "0px 0px 6px 0px rgba(0,178,233,1)",
        },
      },
      elevation4: {
        boxShadow: 0,
      },
      elevation2: {
        border: "1px solid rgba(0,23,44,0.4)",
        "&:hover": {
          border: "1px solid rgba(0,23,44,0.4)",
          boxShadow: "none",
        },
      },
    },
    // MuiTableCell: {
    //   root: {
    //     borderBottom: "1px solid #00182C",
    //     padding: "14px 16px 14px 16px",
    //   },
    // },
    // MuiChip: {
    //   root: {
    //     backgroundColor: "",
    //   },
    // },
    MuiButton: {
      root: {
        backgroundColor: "#ad201c",
        border: "1px solid #ad201c",
        borderRadius: "8px",
        fontSize: "14px",
        color: "rgba(253,245,245)",
        "&:hover": {
          backgroundColor: "rgba(159,46,37, 0.90)",
          border: "1px solid rgba(159,46,37)",
        },
        "&:active": {
          border: "1px solid rgba(159,46,37)",
          backgroundColor: "rgba(159,46,37)",
          opacity: 1,
          color: "#FFFFFF",
        },
      },
      textPrimary: {
        color: "",
      },
      outlinedSizeSmall: {
        marginRight: 8,
      },
    },
    // MuiDrawer: {
    //   paperAnchorDockedLeft: {
    //     borderRight: 0,
    //   },
    // },
    // MuiPickersToolbarButton: {
    //   toolbarBtn: {
    //     padding: 5,
    //   },
    // },
    // MuiPickersDay: {
    //   current: {
    //     color: "#00b3e6",
    //   },
    //   daySelected: {
    //     color: "#FFF",
    //     backgroundColor: "#00B3E6",
    //     "&:hover": {
    //       backgroundColor: "rgba(255, 255, 255, 0.1)",
    //     },
    //   },
    // },
    // MuiPickersClockPointer: {
    //   pointer: {
    //     backgroundColor: "#00B3E6",
    //   },
    // },
    // MuiDivider: {
    //   root: {
    //     backgroundColor: "#00182C",
    //   },
    // },
    // MuiTypography: {
    //   colorPrimary: {
    //     color: "black",
    //   },
    // },
    // MuiListItem: {
    //   button: {
    //     color: "black",
    //     opacity: 0.6,
    //     fontWeight: "bold",
    //     "&:hover": {
    //       color: "black",
    //       opacity: 1,
    //       backgroundColor: "none",
    //     },
    //   },
    // },
    // MuiFormControl: {
    //   root: {
    //     minWidth: 200,
    //   },
    // },
    // MuiPickersToolbar: {
    //   toolbar: {
    //     backgroundColor: "",
    //   },
    // },
    // MuiPickersCalendarHeader: {
    //   iconButton: {
    //     backgroundColor: "",
    //   },
    // },
    // MuiIcon: {
    //   root: {
    //     overflow: "",
    //   },
    // },

    // MuiOutlinedInput: {
    //   root: {
    //     paddingTop: "0px !important",
    //     paddingBottom: "0px !important",
    //   },
    // },
    // MuiDialog: {
    //   paperWidthSm: {
    //     maxWidth: 900,
    //   },
    // },
    // MuiDialogActions: {
    //   root: {
    //     display: "block",
    //     padding: "8px 24px",
    //   },
    //   spacing: {
    //     "&> :not(:first-child)": {
    //       marginLeft: 0,
    //       marginRight: 8,
    //     },
    //   },
    // },
  },

  palette: {
    // backgroundColor: "#00182C",
    primary: {
      main: "#ad201c",
      // light: "#505050",
      // dark: "#002C43",
    },
    secondary: {
      main: "rgb(0,255,0)",
    },
    // type: "dark",
  },
});
